import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  align-items: center;
  background: rgba(255, 254, 254, 0.5);
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 4px 8px;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const NavWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 4px 8px;

  > * {
    margin-left: 6px;
  }
`;
