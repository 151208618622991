import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const LinkComponent = styled(Link)<{ margin: string }>`
  color: #f7941e;
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;
