import * as React from 'react';

import { LinkComponent } from './style';

export interface LinkProps {
  href: string;
  children: any;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  margin?: string;
}

export default function Link({ href, onClick = undefined, margin = "", children }: LinkProps) {
  return (
    <LinkComponent to={href} onClick={onClick} margin={margin}>
      {children}
    </LinkComponent>
  );
}
