import React, { FunctionComponent } from 'react';

import { useUserStore, useUserTypeStore } from 'state/User';

const Footer: FunctionComponent = () => {
  // const user = useUserStore((state) => state.user);
  // const { type } = useUserTypeStore();

  return <div></div>;
};

export default Footer;
