import styled, { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

  ${normalize}

  html, body {
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
  }
`;
