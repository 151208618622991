import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserStore, useUserTypeStore } from 'state/User';
import logoImage from 'assets/images/logo.png';
import { HeaderWrapper, NavWrapper } from './style';
import Link from 'components/Link';

const Header: FunctionComponent = () => {
  const { setAuth, user } = useUserStore();
  const navigate = useNavigate();
  const { setUserType } = useUserTypeStore();

  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setAuth(null);
    setUserType('');
    navigate('/');
  };

  return (
    <HeaderWrapper>
      <Link href="/">
        <img src={logoImage} alt="Brinlo" />
      </Link>
      <NavWrapper>
        {!user?.token && <Link href="/login">Login</Link>}
        {!user?.token && <Link href="/register">Register</Link>}
        {user?.token && <Link href="/tasks">Tasks</Link>}
        {user?.token && (
          <Link href="/" onClick={onLinkClick}>
            Logout
          </Link>
        )}
      </NavWrapper>
    </HeaderWrapper>
  );
};

export default Header;
