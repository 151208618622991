import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserStore, useUserTypeStore } from 'state/User';
import { Box, Container, Flex } from 'styles/Layout';
import Button from 'components/Button';
import { ImageComponent } from 'components/Image/style';
import { H2, H3, Paragraph } from 'styles/Typography';
import homeImage from 'assets/images/home_grafico_principal.png';

const Home: FC = () => {
  const { user } = useUserStore();
  const navigate = useNavigate();
  const { setUserType } = useUserTypeStore();

  const handleClick = (type: string) => {
    setUserType(type);
    navigate('/welcome');
  };

  useEffect(() => {
    if (!user?.token) setUserType('');
  }, []);

  return (
    <Container>
      <Box bg="green" useGrid useHeight>
        <div>
          <H2>¿Qué es BRINLO?</H2>
          <H3 noLineHeight>Si eres Participante</H3>
          <Paragraph>
            Es la nueva plataforma que te permite obtener recompensas por cumplir misiones de nuestros patrocinadores.
          </Paragraph>
          <H3 noLineHeight>Si eres Patrocinador</H3>
          <Paragraph>
            BRINLO entrega recompensas a los usuarios por cumplir misiones de nuestros patrocinadores, generando
            excelentes resultados en difusión, interacción, experimentación.
          </Paragraph>
          {!user?.token && (
            <>
              <H3 align="center">¡¡¡Únete a BRINLO!!!</H3>
              <Flex>
                <Button variant="primary" onClick={() => handleClick('0')} style={{ marginRight: '20px ' }}>
                  Ingresa como Participante
                </Button>
                <Button variant="primary" onClick={() => handleClick('1')}>
                  Ingresa como Patrocinador
                </Button>
              </Flex>
            </>
          )}
        </div>
        <div>
          <ImageComponent src={homeImage} alt="Brinlo" />
        </div>
      </Box>
    </Container>
  );
};

export default Home;
