import React from 'react';

import { ButtonComponent } from './style';

type Props = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant: string;
  children: any;
  style?: React.CSSProperties;
};

const Button = ({ onClick, variant, children, style }: Props) => {
  return (
    <ButtonComponent variant={variant} onClick={onClick} style={style}>
      {children}
    </ButtonComponent>
  );
};

export default Button;
