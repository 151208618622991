import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from 'styles/GlobalStyle';
import { theme } from 'styles/theme';

import Home from 'pages/Home';

import Header from 'components/Header';
// import { useUserStore, useUserTypeStore } from 'state/User';
import Footer from 'components/Footer';

import { AppWrapper } from 'styles';

const Welcome = lazy(() => import('pages/Welcome'));
const Login = lazy(() => import('pages/Login'));
const Register = lazy(() => import('pages/Register'));
const Tasks = lazy(() => import('pages/Tasks'));
const Task = lazy(() => import('pages/ViewTask'));
const EditTask = lazy(() => import('pages/EditTask'));
const Prices = lazy(() => import('pages/Prices'));
const Payment = lazy(() => import('pages/Payment'));
const Forgot = lazy(() => import('pages/Forgot'));

function App() {
  return (
    <AppWrapper>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <header className="header">
          <Header />
        </header>
        <Suspense fallback={<div>loading</div>}>
          <Routes>
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/task/:taskId" element={<Task />} />
            <Route path="/edit/:taskId" element={<EditTask />} />
            <Route path="/create" element={<EditTask />} />
            <Route path="/prices" element={<Prices />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Suspense>
        <footer className="footer">
          <Footer />
        </footer>
      </ThemeProvider>
    </AppWrapper>
  );
}

export default App;
