import styled, { css } from 'styled-components';

export const ButtonComponent = styled.button<{ variant: string }>`
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 12px;
  padding: 11px 20px;
  width: 100%;

  ${(props) =>
    props.variant === 'primary' &&
    css`
      background: #f7941e;
      color: #ffffff;
    `};

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      background: #ffffff;
      color: #8c8989;
    `};
`;
