import styled, { css } from 'styled-components';

export type TypographyProps = {
  align?: string;
  weight?: number;
  margin?: string;
  noLineHeight?: boolean;
  color?: string;
};

export const H2 = styled.h2<TypographyProps>`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #fffdfd;
  margin: 0 0 24px;
  max-width: 100%;

  ${({ align }) =>
    align === 'center' &&
    css`
      text-align: center;
    `}

  @media (min-width: 768px) {
    font-size: 38px;
    line-height: 49px;
  }
`;

export const H3 = styled.h3<TypographyProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #605e5e;

  ${({ align }) =>
    align === 'center' &&
    css`
      text-align: center;
    `}

  ${({ noLineHeight }) =>
    noLineHeight &&
    css`
      line-height: 0;
    `}

  ${({ color }) =>
    color === 'white' &&
    css`
      color: #ffffff;
    `}
`;

export const H4 = styled.h4<TypographyProps>`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px;
  color: #f7941e;
  margin: 0;

  ${({ align }) =>
    align === 'center' &&
    css`
      text-align: center;
    `}
`;

export const Paragraph = styled.p<TypographyProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #58595b;
  margin: 0 0 24px;
  max-width: 100%;
  width: 100%;

  ${({ align }) =>
    align === 'center' &&
    css`
      text-align: center;
    `}

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Bold = styled.p<{ margin?: string }>`
  color: #58595b;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 0;
  text-align: center;

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;

export const Span = styled.span<{ color?: string }>`
  ${({ color }) =>
    color === 'orange' &&
    css`
      color: #f7941e;
    `}

  ${({ color }) =>
    color === 'grey' &&
    css`
      color: #58595b;
    `}
`;
