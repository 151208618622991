import styled, { css } from 'styled-components';
import px2vw from 'utils/px2vw';
import bgBlue from 'assets/images/page-background/fondo-azul.png';
import bgGreen from 'assets/images/page-background/fondo-verde.png';
import bgOrange from 'assets/images/page-background/fondo-naranja.png';
import bgGrey from 'assets/images/page-background/fondo-gris.png';

export const Container = styled.div`
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  min-height: 100%;
  height: 100%;

  @media (min-width: 768px) {
    width: 100%;
    //   display: flex;
    //   margin: ${px2vw(32)};
  }

  // @media (min-width: 1024px) {
  //   flex-wrap: nowrap;
  // }
`;

export const Box = styled.div<{ bg?: string; useGrid?: boolean; useHeight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${px2vw(320, 320)};
  // min-height: ${px2vw(200, 320)};
  flex-direction: column;
  padding: ${px2vw(100)};
  padding-top: 70px;
  background-repeat: no-repeat;
  background-position: center;

  ${(props) =>
    props.useHeight &&
    css`
      height: 100%;
    `};

  ${(props) =>
    props.bg === 'blue' &&
    css`
      background-image: url(${bgBlue});
    `};

  ${(props) =>
    props.bg === 'green' &&
    css`
      background-image: url(${bgGreen});
    `};

  ${(props) =>
    props.bg === 'orange' &&
    css`
      background-image: url(${bgOrange});
    `};

  ${(props) =>
    props.bg === 'grey' &&
    css`
      background-image: url(${bgGrey});
    `};

  @media (min-width: 768px) {
    width: 100%;
    background-size: cover;
    ${(props) =>
      props.useGrid &&
      css`
        display: grid;
        grid-template-columns: 2fr 1fr;
      `};
  }

  // @media (min-width: 1024px) {
  //   width: ${px2vw(500)};
  //   min-height: ${px2vw(300)};
  //   height: 100%;
  // }
`;

export const Flex = styled.div<{}>`
  @media (min-width: 768px) {
    display: fleX;
  }
`;
