import create from 'zustand';

import { User } from 'models/User';
import { persist } from 'zustand/middleware';

interface UserState {
  user: User | null;
  setAuth: (user: User | null) => void;
  doLogin: (email: string, password: string, fetchWrapper: any) => void;
}

export const useUserStore = create<UserState>(
  persist(
    (set, get) => ({
      user: null,
      setAuth: (user) => set({ user }),
      doLogin: async (email: string, password: string, post) => {
        const response = await post(process.env.REACT_APP_API_URL + '/api/auth/login', { email, password });

        if (response.success) {
          const user: User = { username: email, token: response.response.token };
          set({ user });
        } else {
          const user: User = { username: '', token: '' };
          set({ user });
        }
      },
    }),
    {
      name: 'user', // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
);

interface UserTypeState {
  type: string;
  setUserType: (value: string) => void;
}

export const useUserTypeStore = create<UserTypeState>(
  persist(
    (set) => ({
      type: '0',
      setUserType: (type: string) => set({ type }),
    }),
    {
      name: 'type', // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
);
